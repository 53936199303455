import '../sass/frontend.scss';
import 'owl.carousel';
import AOS from 'aos'

(function ($) {
    "use strict";

    $(document).ready(function () {

        /*-------------------------------
            Navbar Fix
        ------------------------------*/
        if ($(window).width() < 991) {
            navbarFix()
        }
        /*------------------------------
            smoth achor effect
        ------------------------------*/
        $(document).on('click', '.navbar-nav li a', function (e) {
            var anchor = $(this).attr('href');
            var link = anchor.slice(0, 1);
            if ('#' == link) {
                e.preventDefault();
                var top = $(anchor).offset().top;
                $('html, body').animate({
                    scrollTop: $(anchor).offset().top
                }, 1000);
                $(this).parent().addClass('current-menu-item').siblings().removeClass('current-menu-item');
            }

        });

        /*--------------------
            AOS js init
        ---------------------*/
        AOS.init();

        /*------------------
            back to top
        ------------------*/
        $(document).on('click', '.back-to-top', function () {
            $("html,body").animate({
                scrollTop: 0
            }, 2000);
        });

        /**-----------------------------
         *  countdown
         * ---------------------------*/
        $('.client-active-area').owlCarousel({
            loop: true,
            items: 4,
            nav: true,
            margin: 100,
            dots: false,
            navText: ['<span data-icon="&#x23;"></span>', '<span data-icon="&#x24;"></span>'],
            responsive: {
                0: {
                    items: 2
                },
                600: {
                    items: 3
                },
                992: {
                    items: 4
                },
                1200: {
                    items: 4
                }
            }
        })
    });


    //define variable for store last scrolltop
    var lastScrollTop = '';

    $(window).on('scroll', function () {

        //back to top show/hide
        var ScrollTop = $('.back-to-top');
        if ($(window).scrollTop() > 1000) {
            ScrollTop.fadeIn(1000);
        } else {
            ScrollTop.fadeOut(1000);
        }

        // Sticky-Memu
        if ($(window).width() > 991) {
            StickyMenu();
        }
    });


    $(window).on('resize', function () {
        /*-------------------------------
            Navbar Fix
        ------------------------------*/
        if ($(window).width() < 991) {
            navbarFix()
        }
    });


    $(window).on('load', function () {
        /*-----------------
             back to top
         ------------------*/
        var backtoTop = $('.back-to-top')
        backtoTop.fadeOut();

    });

    function navbarFix() {
        $(document).on('click', '.navbar-area .navbar-nav li.menu-item-has-children>a, .navbar-area .navbar-nav li.appside-megamenu>a', function (e) {
            e.preventDefault();
        })
    }

    function StickyMenu() {
        /*--------------------------
        sticky menu activation
        ---------------------------*/
        var st = $(this).scrollTop();
        var mainMenuTop = $('.navbar-area');
        if ($(window).scrollTop() > 1000) {
            mainMenuTop.addClass('nav-fixed');
        } else {
            mainMenuTop.removeClass('nav-fixed ');
        }
        lastScrollTop = st;
    }


    document.addEventListener("DOMContentLoaded", function () {
        var navbar = document.querySelector(".navbar-web-cms");

        if (navbar) {
          window.addEventListener("scroll", function () {
              if (window.scrollY > 50) {
                  navbar.classList.add("navbar-scrolled");
              } else {
                  navbar.classList.remove("navbar-scrolled");
              }
          });
        }
    });


})(jQuery);
